import React, { Component } from 'react';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return <div className="about">
            <p>ABOUT</p>
        </div>;
    }
}
 
export default About;